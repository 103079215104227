import React from 'react'
import testImage from '/Users/jessehawke/Desktop/react/portfolio-website/portfolio-website-1/src/img/img-9-1.jpg';

 function Test () {
  return (
    <div>
        <h1> Test </h1>
        < img src ={testImage} alt/>

    </div>
  )
}
export default Test;
