import React from "react";
import '../../App.css'
import './AboutMe.css'


export default function AboutMe(){
    return (
    <div>
        <h1 className="AboutMe">About Me </h1>
        <div className="body-container">
            <div className="body-wrapper"> 
            {/* <h1>0xprey</h1> */}
            <h2 className="aboutMe-body">Yield farmer turned meme lord </h2>
            <p className="body-text-1">
            Known as Wonderland Intern from 2021-2022 (>1M impressions/month at peak), I made content for the greater Wonderland ecosystem (Frog Nation). Using the momentum that Dani/Sifu gathered with their emerging tokens, I established a large following via engaging and topical tweets.
            </p>
            <p className="body-text-1">
            Since, I have been working as a DAO bounty hunter and free-lance content creator. Writing tutorials and meming for various crypto based projects. See my portfolio on the home page for some of my work.          </p>
            <p className="body-text-1">
            I'm currently working at The Doge NFT/Ownthedoge, making memes, tweets, threads and other graphical content. Check out my Tweets page for some example tweets.             
            </p>
            <h2 className="aboutMe-body">What can 0xprey do for you? </h2>
            <p className="body-text-1">
            I can craft high quality project-specific memes and help you create  content for your projects twitter account.

                
            </p>
            <p className="body-text-1">
            Having a great understanding of the Defi, NFT and blockchain,  I will quickly assess how to make entertaining and engaging content for your newly created or already established project.            </p>
            <p className="body-text-1">
            Contact me on Telegram, Discord or Twitter Via the links below.                
            </p>


        </div>
        </div>
    </div>
        

    )
}