import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import WSPGallery from './WSPGallery';
import HomeSection from '../HomeSection';
import CatCards from '../CatCards';


function Home() {
  return (
    <>
      {/* <HeroSection /> */}
      <HomeSection />
      {/* <Cards /> */}
      <CatCards />
      {/* <WSPGallery /> */}





    </>
  );
}

export default Home;